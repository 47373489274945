import React from 'react';
import ambientMall from '../assets/Ambience-1.jpg';
import airiaMall from '../assets/Airia-1.jpg';
import vegasMall from '../assets/Ardee-1.jpg';
import sectionMall from '../assets/Worldmark.jpg';

const FeaturedMalls = () => {
  const malls = [
    {
      id: 1,
      name: "Ambience Mall",
      location: "Gurugram",
      image: ambientMall,
      outlets: "60+ Outlets",
    },
    {
      id: 2,
      name: "Airia Mall",
      location: "Gurugram",
      image: airiaMall,
      outlets: "40+ Outlets",
    },
    {
      id: 3,
      name: "Ardee Mall",
      location: "Gurugram",
      image: vegasMall,
      outlets: "50+ Outlets",
    },
    {
      id: 4,
      name: "Worldmark Mall",
      location: "Gurugram",
      image: sectionMall,
      outlets: "10+ Outlets",
    }
  ];

  return (
    <div className="bg-black py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl md:text-4xl font-bold text-white text-center mb-4">
          Markets Near You
        </h1>
        <p className="text-center text-lg md:text-xl text-gray-300 font-light mb-10">
          Explore Premium Shopping Destinations
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8">
          {malls.map((mall) => (
            <div
              key={mall.id}
              className="relative transform transition duration-300 hover:scale-105 cursor-pointer group"
            >
              <div className="relative rounded-lg overflow-hidden bg-white/5 border border-white/10">
                <img
                  src={mall.image}
                  alt={mall.name}
                  className="w-full h-[320px] md:h-[380px] object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent">
                  <div className="absolute top-4 right-4">
                    <div className="bg-white/10 backdrop-blur-sm text-white px-4 py-2 rounded-lg text-sm font-medium">
                      {mall.outlets}
                    </div>
                  </div>
                  <div className="absolute inset-x-0 bottom-0 p-6">
                    <h2 className="text-xl md:text-2xl font-bold mb-2 text-white group-hover:text-gray-200">
                      {mall.name}
                    </h2>
                    <p className="text-sm md:text-base text-gray-300 group-hover:text-gray-400">
                      {mall.location}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedMalls; 