import React from 'react';
import malls from '../assets/1.png';
import brands from '../assets/2.png';
import discover from '../assets/3.png';
import { Dot } from 'lucide-react';

const GetStartedSection = ({ onCardClick }) => {
  const cards = [
    {
      id: 1,
      title: 'MALLS',
      subtitle: 'Markets Near you',
      buttonText: 'LIVE NOW',
      buttonClass: 'bg-white/10',
      bgImage: malls,
    },
    {
      id: 2,
      title: 'BRANDS',
      subtitle: 'Outlets Near you',
      buttonText: 'SHOP NOW',
      buttonClass: 'bg-white/10',
      bgImage: brands,
    },
    {
      id: 3,
      title: 'DISCOVER',
      subtitle: "What's New",
      buttonText: 'EXPLORE',
      buttonClass: 'bg-white/10',
      bgImage: discover,
    },
  ];

  return (
    <div className="relative py-6 px-4 md:py-10 md:px-8 lg:px-12 bg-black">
      <h1 className="text-3xl md:text-4xl font-bold text-white text-center mb-12">Get Started</h1>
      <div className="flex flex-col items-center">
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 md:gap-8 w-full max-w-6xl mx-auto">
          {cards.map((card, index) => (
            <div
              key={card.id}
              className="relative transform transition duration-300 hover:scale-105 cursor-pointer"
              onClick={() => onCardClick?.[index]?.()}
            >
              <div className="relative rounded-xl w-full h-[220px] sm:h-[240px] md:h-[280px] lg:h-[320px] overflow-hidden bg-white/5 border border-white/10">
                <img
                  src={card.bgImage}
                  alt={card.title}
                  className="w-full h-full object-cover opacity-80"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent">
                  <div className="absolute top-4 right-4">
                    <div className={`${card.buttonClass} backdrop-blur-sm text-white px-4 py-2 rounded-lg text-sm font-medium flex items-center`}>
                      <Dot className="w-5 h-5 text-green-400" />
                      {card.buttonText}
                    </div>
                  </div>
                  <div className="absolute inset-x-0 bottom-0 p-6">
                    <h2 className="text-xl md:text-2xl font-bold mb-2 text-white">
                      {card.title}
                    </h2>
                    <p className="text-sm md:text-base text-gray-300">
                      {card.subtitle}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GetStartedSection;
