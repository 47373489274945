import React from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

const FAQ = () => {
  const questions = [
    {
      question: 'What is Zulu Club?',
      answer: 'Zulu Club is a premium shopping platform that combines the convenience of e-commerce with the curated experience of malls enabled via video calls with your Zulu appointed shopper, delivering items to your doorstep in 100 minutes or less.',
    },
    {
      question: 'What can I shop on Zulu Club?',
      answer: 'You can discover, browse, and shop the latest collections from your favorite fashion brands, malls, and outlets near you. Users can shop from Airia mall currently and will soon expand to various malls in Gurgaon.',
    },
    {
      question: 'Where is Zulu Club operational?',
      answer: 'We are now live for video tours and deliveries at Airia Mall! You can also explore outlets at other locations, including Ardee Mall, AIPL Joystreet, M3M Urbana, and MGF Metropolitan Mall. We are continuously expanding our presence to offer you more options for discovery and shopping.',
    },
    {
      question: 'How does the video tour feature work?',
      answer: 'You can book a video tour directly through the app, where a shopper will be assigned to assist you. From there, you can coordinate via WhatsApp, connect through a video call, and receive photos of clothing items from the outlet for a seamless shopping experience.',
    },
    {
      question: 'Is there a return/exchange policy?',
      answer: 'We are a platform which connects you to the store and adhere to the same policy as the store for return and exchange. But we will not shy away from offering logistics support by offering services such as pickup and video calls for replacement in case of exchange.',
    },
    {
      question: 'Can I pick up my order from the outlet?',
      answer: 'Yes, you have the option to pick up your order from the outlet or have it delivered to your home. For pick up, the shopper allotted to you will assist you with the items you would have selected.',
    },
  ];

  return (
    <section className="py-16 bg-black mt-[50px] md:mt-[230px]">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-white">
          Frequently Asked Questions
        </h2>
        <div className="max-w-3xl mx-auto space-y-4">
          {questions.map((faq, index) => (
            <Disclosure key={index}>
              {({ open }) => (
                <div className="border border-gray-700 rounded-lg overflow-hidden">
                  <Disclosure.Button className="w-full px-6 py-4 text-left flex justify-between items-center bg-white/5 hover:bg-white/10 transition-colors duration-200">
                    <span className="text-white font-medium">{faq.question}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? 'transform rotate-180' : ''
                      } w-5 h-5 text-white transition-transform duration-200`}
                    />
                  </Disclosure.Button>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel className="px-6 py-4 bg-white/5 text-gray-300">
                      {faq.answer}
                    </Disclosure.Panel>
                  </Transition>
                </div>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
