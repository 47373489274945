class ConversationalAgent {
  constructor() {
    this.SYSTEM_PROMPT = `You are a friendly retail shopping assistant at Zulu helping customers find clothes and accessories. 

    IMPORTANT: You must ALWAYS respond with valid JSON in this exact format:
    {
      "reply": "your conversation response here",
      "context": {
        "category": "t-shirt/shirt/etc or null",
        "gender": "men/women or null",
        "conversation_complete": boolean
      }
    }

    Follow these rules:
    1. Only collect these two pieces of information:
       - Category (clothing type)
       - Gender (men's/women's)
       -Brands
       -Attributes
       -color
       -fit
       -mall
       -material
       -occasion
       -price
       -style
       -occasions
       -activities
       -seasons
       -weather
       -tags
       -searchKeywords
       -usage
    
    Conversation Rules:
    - Always be friendly and natural
    - Ask only one question at a time
    - Set conversation_complete: true as soon as you have both category and gender
    - If user says something like "show me something", ask specifically what type of clothing they're interested in
    - Keep responses concise and focused
    - Zulu Club: Revolutionizing Fashion Shopping

1. About Zulu
Zulu Club lets you shop from malls and markets right from your home. With the Zulu app, users can easily explore stores, browse product catalogues, and shop in real time through live video calls. Zulu makes shopping smarter, faster, and more enjoyable for everyone. It bridges the gap between the traditional mall experience and the convenience of online shopping, offering a truly unique approach tailored to today's busy lifestyles.

2. How Zulu Works
Zulu Club enhances your shopping experience through these key features:

Explore Malls and Stores: Use the Zulu app to browse through malls, explore fashion outlets, and view catalogues of your favorite brands from the comfort of your home.

Live Video Shopping Tours: Enjoy real-time video tours of stores guided by personal shoppers who help you find exactly what you need, offering personalized suggestions and showing you the latest collections.

"Ask Zulu" Feature: Chat with a real person or an AI assistant to search for products, request detailed photos or videos, and receive instant assistance for any shopping-related query.

Fast Delivery: Once you make a purchase, your items will be delivered to your doorstep within just 100 minutes, ensuring a seamless shopping experience.

Hassle-Free Returns and Exchanges: If needed, return or exchange products according to the outlet's policies. Zulu ensures clear communication regarding these policies for a smooth process.

Window Shopping Made Easy: Take as much time as you want to explore without any pressure to make a purchase during video calls. Perfect for when you're just browsing or planning future purchases.

3. Why Should People Use Zulu?
Zulu Club combines the best aspects of mall shopping and e-commerce, making it the perfect choice for busy individuals:

Save Valuable Time: Skip the hassle of traffic, parking queues, trial room waits, and billing lines. Shop from home and save your weekends.

Reliable and Fast: Unlike e-commerce, where deliveries can take days and returns may drag on for weeks, Zulu offers prompt deliveries and smooth return processes.

Unmatched Variety: Access a vast range of products, including Indian ethnic wear, premium brands, local favorites, and exclusive legacy outlets.

Convenience with Experience: Enjoy the diverse, high-quality offerings of malls with the ease and efficiency of online shopping.

Collaborative Shopping: Shop together with family and friends virtually, making decisions easier and more enjoyable.

Smart Planning: Discover what's available before heading to the mall, ensuring you spend time only in the stores that match your preferences.

For those juggling a busy schedule, Zulu is the smartest way to shop without sacrificing quality or the joy of discovery.

4. Where is Zulu Available?
Zulu Club is based in India and currently caters exclusively to residents of Gurgaon. Shoppers can access major malls in Gurgaon, featuring a wide range of top brands, including ethnic wear, luxury labels, and everyday essentials.

In 2025, Zulu plans to expand to iconic markets in Delhi, bringing even more exciting shopping opportunities to its users. Stay tuned as Zulu grows to cover more regions and markets.

5. About the Company
Zulu Club is a passionate and innovative startup, currently operating in its beta phase. We are dedicated to creating a seamless and enjoyable shopping experience by blending technology with the best of traditional mall visits.

As a young company, we are constantly learning and evolving. While we may encounter challenges along the way, we are committed to improving with your valuable feedback. Your support helps us grow, and in return, we promise to keep refining Zulu to better serve your shopping needs.

6. Challenges & Solutions
Zulu Club works hard to ensure a smooth shopping experience, but some challenges may arise:

Network Connectivity Issues: Some outlets may face connectivity problems. To address this, our personal shoppers carry multiple SIM cards to ensure uninterrupted service. Alternatively, users can switch to our chat option to receive videos and communicate directly with our team for product selection.

Crowded Outlets: Occasionally, stores may be too busy to provide the best experience. In such cases, our shopper will coordinate with you to reschedule your appointment at a more convenient time.

7. Ecom vs. Qcom: Zulu's Unique X-Com Model
Is Zulu's model similar to quick commerce (Qcom)? Well, kind of—it does make shopping faster—but Zulu goes beyond that with its innovative X-Com (Experiential Commerce) format. Here's how:

Personalized Assistance: One of the biggest gaps in e-commerce is the lack of real-time assistance. If you have questions about a product, there's often no one to help you. Zulu bridges this gap by offering access to personal shoppers who can guide you in real time.

Curated Selection: Browsing through hundreds of similar-looking products on e-commerce platforms can be overwhelming. Zulu solves this by providing a curated shopping experience where personal shoppers or AI assistants help you find exactly what you're looking for without the hassle.

Zulu is designed to address these common frustrations, making it a smarter and more enjoyable way to shop. Zulu combines speed, convenience, and personal service to offer something better than e-commerce or quick commerce.

8. Competition and Peers
Fashion is a dynamic industry, and the rise of quick commerce (Qcom) is bringing significant changes to how people shop. Innovations in fashion tech are accelerating, with many players exploring new formats. Most fashion tech companies are building warehouse-based supply chains that closely resemble traditional e-commerce models. These innovations focus on faster deliveries but often compete directly with branded showrooms and malls, requiring substantial capital investments to build new warehouses and manage inventory—a practice that may not be sustainable for the industry.

Zulu Club stands apart by offering a truly unique and innovative shopping experience without disrupting the existing infrastructure. Rather than competing with brands, Zulu empowers them. By promoting the latest in-store collections, supporting unique visual merchandising, and enhancing the overall shopping journey, Zulu aligns with what brands aim to achieve.

This approach makes Zulu's model not only sustainable but also exciting and fresh for the industry. It combines the strengths of traditional retail with modern technology to create an experience that benefits both consumers and brands alike.

9. What Can You Shop on Zulu Currently?
Zulu is currently live in three major malls in Gurgaon: Ambience Mall, Ardee Mall, and Airia Mall. Across these malls, you can explore over 100 unique brands, ranging from fashion and accessories to specialty stores and more.

Zulu is committed to expanding its coverage soon, promising to bring even more markets and brands online to enrich your shopping experience.

10. How Does Ask Zulu Chatbot Work?
The Ask Zulu chatbot is your personal shopping assistant, helping you find malls, brands, outlets, and products with ease. Here's how it works:

Smart Search: You can browse the app to find what you like or simply ask Zulu to locate it for you. The chatbot navigates the options and directs you to the right page.

Understanding Your Needs: The AI agent begins by understanding your requirements, such as product types, styles, or specific preferences.

Shortlisted Products: Based on your input, the chatbot filters products and presents a curated selection tailored to your needs.

Simple Commands: You can give simple instructions like "show me casual dresses under ₹2000," and Zulu will handle the rest, ensuring a smooth experience.

11. How Does the Video Tour Work?
Zulu's Video Tour feature brings the mall experience to your fingertips. Here's how it works:

Choose Your Outlet: Select any store you'd like to explore virtually. You can either start a tour instantly or schedule it for a time that suits you.

Personal Shoppers in Action: Zulu shoppers, stationed in malls, will rush to the outlet of your choice once the tour is booked.

Flexible Communication: They'll reach out to you on WhatsApp and offer assistance via audio, video, or chat—depending on your preference.

Live Video and Media Support: You don't need to switch on your own video. You can ask the shopper to go live or send detailed videos and images of products you like, ensuring a seamless and private experience.

12. How Does Zulu Earn Money?
Zulu Club is not currently focused on earning revenue. Instead, we prioritize adding value to both consumers and outlets. Our belief is simple: if we genuinely help consumers shop smarter and assist outlets in meaningful ways, both will recognize our efforts and reward us fairly in the long run.

Our focus is on building a strong value proposition first. We are confident that if we succeed in this mission, monetization and profitability will naturally follow.

If you are curious to learn more about our vision and strategy, feel free to email us at hi@zulu.club, and we'd be happy to hop on a quick call to discuss. We believe the market is evolving rapidly, with both consumer behavior and brand challenges shifting. This is the perfect time to bring positive change to the industry. Support us if you share our vision, and together, let's create something extraordinary.

13. Zulu and AI
We want Zulu chat to be your personal fashion shopping buddy. The more you browse, chat, or explore through video tours, the better we will understand your preferences and style. Over time, your chat experience will become increasingly personalized, making shopping even more convenient and enjoyable.

If you have additional ideas or suggestions for how we can enhance this experience, don't hesitate to reach out to us. Just send an email to hi@zulu.club, and we'll be happy to chat in detail. Let's work together to create the ultimate fashion shopping companion!

14. Who is the Target Audience of Zulu and What are the Target Purchase Behaviors?
Zulu is for everyone, no matter your shopping needs:

Last-Minute Fashion Needs: Heading out for a party and don't like your wardrobe? Get something fresh within 100 minutes. Need an urgent gift? No problem, Zulu has you covered.

Window Shopping: If you only get time to visit the mall on weekends, why not discover what you want beforehand? With Zulu, you can spend your time in the outlets that actually have what you're looking for.

Shop with Your Loved Ones: Need the opinion of family or friends when buying something important? Zulu makes it easy to shop together from the comfort of your home.

Young Parents: We understand how difficult it can be to visit a mall or shop with infants and toddlers. With Zulu, you can shop conveniently without the stress.

Occasion Wear: For major occasions, browsing multiple malls and brands can take weeks and be exhausting. Zulu offers a smarter way to explore and find exactly what you need quickly and effortlessly.

15. How Can This Information Be Used?
This document provides comprehensive details about Zulu Club, enabling the AI assistant to handle diverse user queries effectively. The AI should:

Adapt responses based on the user's tone and intent, such as providing brief overviews or detailed explanations.

Utilize specific sections, such as shopping behaviors, challenges, or the X-Com model, to craft contextual replies.

Be capable of addressing practical concerns, such as delivery times, regions served, and shopping features, while incorporating Zulu's value-driven mission.

Encourage deeper engagement by inviting users to provide feedback, share ideas, or explore Zulu's services further.

The AI's goal is to provide a seamless, informative, and engaging experience for every user.`;
     

    // API endpoints
    this.FILTER_PRODUCTS_API = 'https://api.zulu.club/filter-products';
    this.RECOMMENDATIONS_API = 'https://api.zulu.club/get-recommendations';
    this.CHAT_API = 'http://82.112.227.11:5000/chat';

    // Valid categories mapping
    this.VALID_CATEGORIES = {
      'skirt': 'skirt',
      'skirts': 'skirt',
      'shirt': 'shirt',
      'shirts': 'shirt',
      't-shirt': 'tshirt',
      'tshirt': 'tshirt',
      't-shirts': 'tshirt',
      'tshirts': 'tshirt',
      'pant': 'pant',
      'pants': 'pant',
      'jean': 'jeans',
      'jeans': 'jeans',
      'dress': 'dress',
      'dresses': 'dress',
      'jacket': 'jacket',
      'jackets': 'jacket'
    };
  }

  normalizeCategory(category) {
    const normalizedCategory = category?.toLowerCase().trim();
    return this.VALID_CATEGORIES[normalizedCategory] || null;
  }

  // Add retry utility function
  async retryFetch(url, options, maxRetries = 3) {
    for (let i = 0; i < maxRetries; i++) {
      try {
        const response = await fetch(url, {
          ...options,
          timeout: 5000 // 5 second timeout
        });
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error(`Attempt ${i + 1} failed:`, errorText);
          if (i === maxRetries - 1) throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          return response;
        }
      } catch (error) {
        console.error(`Attempt ${i + 1} failed:`, error);
        if (i === maxRetries - 1) throw error;
        // Wait before retrying (exponential backoff)
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, i) * 1000));
      }
    }
  }

  async fetchChatResponse(conversationHistory = []) {
    try {
      const response = await this.retryFetch(this.CHAT_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          conversation_history: conversationHistory.map(msg => ({
            text: msg.text,
            sender: msg.sender
          }))
        })
      });

      const data = await response.json();
      console.log('Chat API response:', data);
      
      if (data && data.success === true) {
        return {
          reply: data.response,
          context: {
            // Product attributes
            attributes: {
              color: data.context.attributes?.color || null,
              fit: data.context.attributes?.fit || null,
              mall: data.context.attributes?.mall || null,
              material: data.context.attributes?.material || null,
              occasion: data.context.attributes?.occasion || null,
              price: {
                max: data.context.attributes?.price?.max || null,
                min: data.context.attributes?.price?.min || null
              },
              style: data.context.attributes?.style || null
            },
            
            // Brand information
            brands: data.context.brands || [],
            
            // Basic categorization
            category: data.context.category || null,
            gender: data.context.gender || null,
            
            // Contextual information
            contextual_notes: {
              implicit_needs: data.context.contextual_notes?.implicit_needs || null,
              occasion_context: data.context.contextual_notes?.occasion_context || null,
              style_context: data.context.contextual_notes?.style_context || null,
              user_preferences: data.context.contextual_notes?.user_preferences || null
            },
            
            // Conversation state
            conversation_complete: data.context.conversation_complete || false,
            next_attribute_to_ask: data.context.next_attribute_to_ask || null,
            
            // Search and filtering
            searchKeywords: data.context.searchKeywords || [],
            tags: data.context.tags || [],
            
            // Usage context
            usage: {
              activities: data.context.usage?.activities || [],
              occasions: data.context.usage?.occasions || [],
              seasons: data.context.usage?.seasons || [],
              weather: data.context.usage?.weather || []
            }
          }
        };
      }

      console.error('Invalid chat API response:', data);
      return null;

    } catch (error) {
      console.error('Error fetching chat response:', error);
      return {
        error: true,
        message: 'Unable to process chat at the moment. Please try again later.'
      };
    }
  }

  async fetchFilteredProducts(gender, category) {
    try {
      const normalizedCategory = this.normalizeCategory(category);
      
      if (!normalizedCategory || !gender) {
        console.log('Missing required filters:', { gender, category });
        return [];
      }

      // Update request body to match API requirements
      const requestBody = {
        category: normalizedCategory.toLowerCase(),
        gender: gender.toLowerCase(),
        brands: [],
        attributes: {
          price: {
            min: null,
            max: null
          }
        },
        usage: {
          occasions: [],
          activities: [],
          seasons: [],
          weather: []
        },
        tags: [`${gender.toLowerCase()}'s fashion`],
        searchKeywords: [`${gender.toLowerCase()}'s ${normalizedCategory.toLowerCase()}`]
      };

      console.log('Fetching filtered products:', requestBody);
      
      const response = await this.retryFetch(this.FILTER_PRODUCTS_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Accept': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Methods': 'POST, OPTIONS',
          // 'Access-Control-Allow-Headers': 'Content-Type'
        },
        mode: 'cors',
        //credentials: 'include',
        body: JSON.stringify(requestBody)
      });

      const data = await response.json();
      console.log('Raw API response:', data);

      // Check if the response has the expected structure
      if (data && data.success === true) {
        if (!data.products || data.products.length === 0) {
          console.log('No products found for filters:', requestBody);
          return [];
        }
        // Return both products and csv_path
        return {
          products: data.products,
          csv_path: data.csv_path
        };
      } else {
        console.error('Invalid API response structure:', data);
        return [];
      }

    } catch (error) {
      console.error('Error fetching filtered products:', error);
      return {
        error: true,
        message: 'Unable to fetch products at the moment. Please try again later.'
      };
    }
  }

  async fetchRecommendations(filteredData, conversationHistory = [], currentQuery = '') {
    try {
      if (!filteredData || !filteredData.products || filteredData.products.length === 0 || !filteredData.csv_path) {
        return null;
      }

      // Format conversation history and include current user message
      const formattedHistory = [
        ...conversationHistory.map(msg => ({
          text: msg.text,
          sender: msg.sender
        })),
        // Add the current user's query/message
        {
          text: currentQuery,
          sender: 'user'
        }
      ];

      console.log('Formatted conversation history:', formattedHistory);

      // Create filter context in the same format as filter-products request
      const filterContext = {
        category: String(filteredData.products[0].classification?.category?.sub || ''),
        gender: String(filteredData.products[0].demographic?.gender || ''),
        brands: [],
        attributes: {
          price: {
            min: '',
            max: ''
          }
        },
        usage: {
          occasions: [],
          activities: [],
          seasons: [],
          weather: []
        },
        tags: [`${String(filteredData.products[0].demographic?.gender || '')}'s fashion`],
        searchKeywords: [
          `${String(filteredData.products[0].demographic?.gender || '')}'s ${String(filteredData.products[0].classification?.category?.sub || '')}`
        ],
        recommendations_count: 4,
      };

      const requestBody = {
        products: filteredData.products,
        conversation_history: formattedHistory,
        filter_context: filterContext,
        csv_path: String(filteredData.csv_path),
              
      };

      console.log('Fetching recommendations for products:', requestBody);

      const response = await this.retryFetch(this.RECOMMENDATIONS_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      // Clean and parse the response
      const responseText = await response.text();
      
      // Replace NaN with null in the response text
      const cleanedResponse = responseText
        .replace(/:\s*NaN/g, ': null')
        .replace(/:\s*Infinity/g, ': null')
        .replace(/:\s*-Infinity/g, ': null')
        .replace(/:\s*undefined/g, ': null');

      try {
        const data = JSON.parse(cleanedResponse);
        console.log('Recommendations API response:', data);

        if (data && data.success === true) {
          // Extract recommended_products directly from the response
          const recommendedProducts = data.recommendations?.recommended_products || [];
          
          // Clean up explanations by removing IDs
          const cleanExplanations = {};
          Object.entries(data.recommendations?.explanations || {}).forEach(([id, explanation]) => {
            cleanExplanations[id] = explanation
              .replace(/\s*\([^)]*\)/g, '') // Remove anything in parentheses
              .replace(/\b\d{4,}\b/g, '')   // Remove 4+ digit numbers
              .replace(/\s+/g, ' ')         // Clean up extra spaces
              .trim();
          });

          // Clean up style suggestions by removing IDs
          const cleanSuggestions = (data.recommendations?.style_suggestions || [])
            .map(suggestion => suggestion
              .replace(/\s*\([^)]*\)/g, '') // Remove anything in parentheses
              .replace(/\b\d{4,}\b/g, '')   // Remove 4+ digit numbers
              .replace(/\s+/g, ' ')         // Clean up extra spaces
              .trim()
            );

          return {
            recommendations: {
              recommended_products: recommendedProducts,
              explanations: cleanExplanations,
              style_suggestions: cleanSuggestions
            }
          };
        }
        
        return null;
      } catch (parseError) {
        console.error('Failed to parse recommendations response:', parseError);
        return null;
      }

    } catch (error) {
      console.error('Error fetching recommendations:', error);
      return null;
    }
  }

  async handleQuery(query, conversationHistory = []) {
    try {
      const messages = [
        {
          role: "system",
          content: this.SYSTEM_PROMPT
        },
        ...conversationHistory.map(msg => ({
          role: msg.sender === 'user' ? 'user' : 'assistant',
          content: msg.sender === 'user' ? msg.text : JSON.stringify({
            reply: msg.text,
            context: msg.context || {}
          })
        })),
        {
          role: "user",
          content: query
        }
      ];

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
        },
        body: JSON.stringify({
          model: "gpt-4",
          messages: messages
        })
      });

      const data = await response.json();
      const content = data.choices[0].message.content;
      
      try {
        const parsedResponse = JSON.parse(content);
        
        // If we have both gender and category, fetch products
        if (parsedResponse.context.gender && parsedResponse.context.category) {
          // Step 1: Get filtered products
          const filteredData = await this.fetchFilteredProducts(
            parsedResponse.context.gender,
            parsedResponse.context.category
          );

          // Check if we got an error object
          if (filteredData.error) {
            return {
              ...parsedResponse,
              reply: filteredData.message,
              filteredProducts: [],
              error: true
            };
          }

          // Step 2: If we have filtered products and csv_path, get recommendations
          if (filteredData.products && filteredData.products.length > 0) {
            // Pass conversation history to fetchRecommendations
            const recommendations = await this.fetchRecommendations(
              filteredData,
              conversationHistory,
              query
            );

            // Get the recommended product IDs from get-recommendations API
            const recommendedIds = recommendations?.recommendations?.recommended_products || [];
            
            // Map these IDs to the full product objects we got from filter-products API
            const recommendedProducts = recommendedIds
              .map(id => filteredData.products.find(p => String(p.id) === String(id)))
              .filter(p => p !== undefined);

            console.log('Filtered Products:', filteredData.products);
            console.log('Recommended IDs:', recommendedIds);
            console.log('Mapped Products:', recommendedProducts);

            return {
              ...parsedResponse,
              filteredProducts: filteredData.products,
              recommendations: {
                products: recommendedProducts,
                explanations: recommendations?.recommendations?.explanations || {},
                style_suggestions: recommendations?.recommendations?.style_suggestions || '',
                recommendations_count: recommendedProducts.length
              }
            };
          }

          // Return response with empty products if none found
          return {
            ...parsedResponse,
            reply: `I couldn't find any ${parsedResponse.context.gender}'s ${parsedResponse.context.category} at the moment. Would you like to try a different category?`,
            filteredProducts: []
          };
        }

        return parsedResponse;

      } catch (parseError) {
        console.error('Invalid JSON response:', content);
        return {
          reply: "I'm sorry, I encountered an error. Could you please try again?",
          context: {
            category: null,
            gender: null,
            conversation_complete: false
          }
        };
      }
    } catch (error) {
      console.error('API Error:', error);
      return {
        reply: "I'm having trouble connecting to our product database. Please try again in a moment.",
        context: {
          category: null,
          gender: null,
          conversation_complete: false
        },
        error: true
      };
    }
  }
}

export default ConversationalAgent; 