import React, { useState, forwardRef } from 'react';
import { ChevronRight, Instagram } from 'lucide-react';
import chatbotIcon from '../assets/5.svg';
import videoIcon from '../assets/4.svg';
import { useNavigate } from 'react-router-dom';
import shopNowGif from '../assets/SHOP NOW (23).png';
import bgImage from '../assets/SHOP NOW (22).png';

const HeroSection = forwardRef((props, ref) => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [name, setName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNameInput, setShowNameInput] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    if (value.length <= 10) {
      setMobileNumber(value);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const getISTTimestamp = () => {
    const now = new Date();
    const istTime = new Date(now.getTime() + (5.5 * 60 * 60 * 1000));
    return istTime.toISOString()
      .replace('Z', '+05:30')
      .slice(0, 16);
  };

  const handleMobileSubmit = async () => {
    const mobileRegex = /^\d{10}$/;

    if (!mobileRegex.test(mobileNumber.trim())) {
      alert('Please enter a valid 10-digit mobile number');
      return;
    }

    setIsSubmitting(true);

    try {
      const timestamp = getISTTimestamp();
      const formData = new URLSearchParams();
      formData.append('mobileNumber', mobileNumber.trim());
      formData.append('timestamp', timestamp);

      const response = await fetch('https://hooks.zapier.com/hooks/catch/21065465/28s8lvn/', {
        method: 'POST',
        mode: 'no-cors',
        body: formData
      });

      console.log('Request sent successfully');
      console.log('Submitted Data:', {
        mobileNumber: mobileNumber.trim(),
        timestamp: timestamp
      });
      
      setShowNameInput(true);
      
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error submitting your mobile number. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNameSubmit = async () => {
    if (!name.trim()) {
      alert('Please enter your name');
      return;
    }

    setIsSubmitting(true);

    try {
      const timestamp = getISTTimestamp();
      const formData = new URLSearchParams();
      formData.append('name', name.trim());
      formData.append('mobileNumber', mobileNumber.trim());
      formData.append('timestamp', timestamp);
      formData.append('Type', 'Customer');

      const response = await fetch('https://hooks.zapier.com/hooks/catch/21065465/28s8lvn/', {
        method: 'POST',
        mode: 'no-cors',
        body: formData
      });

      console.log('Request sent successfully');
      console.log('Submitted Data:', {
        name: name.trim(),
        mobileNumber: mobileNumber.trim(),
        timestamp: timestamp
      });
      
      setShowNameInput(false);
      setShowThankYou(true);
      
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error submitting your name. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div 
      ref={ref} 
      className="relative min-h-[80vh] flex items-center justify-center"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: 1,
        backgroundColor: 'transparent'
      }}
    >
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 w-full bg-transparent">
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
          {/* Left side with GIF */}
          <div className="w-full md:w-1/2 flex flex-col items-center justify-center">
            <img 
              src={shopNowGif} 
              alt="Shop Now" 
              className="w-full h-auto max-w-[540px] object-contain"
            />
            <p className="text-2xl md:text-3xl font-bold text-white mt-3">
              Fashion in 100mins
            </p>
            <a 
              href="https://www.instagram.com/zuluclubindia?igsh=YWg4ZXFnenFzMWM4"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-3 mt-4 text-gray-300 hover:text-white transition-all duration-200"
            >
              <Instagram className="w-6 h-6" />
              <span className="text-base">Follow us on Instagram</span>
            </a>
          </div>

          {/* Right side content */}
          <div className="w-full lg:w-1/2">
            <div className="max-w-xl mx-auto space-y-12 mt-16">
              {/* Feature Boxes */}
              <div className="flex flex-col space-y-10">
                {/* Ask Zulu Box */}
                <div className="group relative transform hover:-translate-y-1 transition-all duration-300">
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-blue-500/20 rounded-2xl 
                                blur-xl opacity-60 group-hover:opacity-80 transition-opacity duration-300" />
                  <div className="relative bg-black/40 backdrop-blur-md border-2 border-white/20 rounded-2xl p-10
                                group-hover:bg-black/50 group-hover:border-white/30 transition-all duration-300">
                    <div className="flex items-center justify-between gap-8">
                      <div className="flex items-center gap-8">
                        <div className="w-24 h-24 flex items-center justify-center bg-gradient-to-b from-white/20 to-transparent rounded-full
                                      group-hover:from-white/30 transition-all duration-300">
                          <img 
                            src={chatbotIcon} 
                            alt="Ask Zulu"
                            className="w-16 h-16 object-contain opacity-90 group-hover:opacity-100 transition-all duration-300"
                          />
                        </div>
                        <div className="flex flex-col">
                          <span className="text-white text-2xl font-bold tracking-wide mb-2">ASK ZULU</span>
                          <span className="text-white/70 text-lg">Fashion Chatbot</span>
                        </div>
                      </div>
                      <button 
                        onClick={() => navigate('/ask-zulu')}
                        className="px-10 py-4 bg-black/40 backdrop-blur-sm border-2 border-white/20 rounded-full
                                 text-white text-lg font-medium tracking-wide whitespace-nowrap
                                 hover:bg-black/60 hover:border-white/30
                                 transition-all duration-300 transform hover:scale-[1.02]"
                      >
                        Try Now
                      </button>
                    </div>
                  </div>
                </div>

                {/* Video Tour Box */}
                <div className="group relative transform hover:-translate-y-1 transition-all duration-300">
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-blue-500/20 rounded-2xl 
                                blur-xl opacity-60 group-hover:opacity-80 transition-opacity duration-300" />
                  <div className="relative bg-black/40 backdrop-blur-md border-2 border-white/20 rounded-2xl p-10
                                group-hover:bg-black/50 group-hover:border-white/30 transition-all duration-300">
                    <div className="flex items-center justify-between gap-8">
                      <div className="flex items-center gap-8 min-w-0">
                        <div className="w-24 h-24 flex-shrink-0 flex items-center justify-center bg-gradient-to-b from-white/20 to-transparent rounded-full
                                      group-hover:from-white/30 transition-all duration-300">
                          <img 
                            src={videoIcon} 
                            alt="Video Tour"
                            className="w-16 h-16 object-contain opacity-90 group-hover:opacity-100 transition-all duration-300"
                          />
                        </div>
                        <div className="flex flex-col min-w-0">
                          <span className="text-white text-2xl font-bold tracking-wide mb-2 truncate">VIDEO TOUR</span>
                          <span className="text-white/70 text-lg whitespace-nowrap">Malls & Outlets</span>
                        </div>
                      </div>
                      <button 
                        onClick={() => setShowDownloadPopup(true)}
                        className="flex-shrink-0 px-10 py-4 bg-black/40 backdrop-blur-sm border-2 border-white/20 rounded-full
                                 text-white text-lg font-medium tracking-wide whitespace-nowrap
                                 hover:bg-black/60 hover:border-white/30
                                 transition-all duration-300 transform hover:scale-[1.02]"
                      >
                        Shop Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <h1 className="text-2xl lg:text-3xl font-bold text-white/90 text-center tracking-wide">
                # Do the Zulu
              </h1> */}
            </div>
          </div>
        </div>
      </div>

      {/* Download App Popup */}
      {showDownloadPopup && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white/10 backdrop-blur-lg border border-white/10 rounded-lg p-6 max-w-sm w-full mx-4">
            <div className="text-center space-y-4">
              <h3 className="text-xl font-bold text-white">Download Zulu App</h3>
              <p className="text-sm text-gray-300">
                To view product details and make purchases, please download our mobile app
              </p>
              
              <div className="flex flex-col gap-3 mt-6">
                <a 
                  href="https://play.google.com/store/apps/details?id=com.zulu.consumer.zulu_consumer"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center gap-2 bg-white/10 hover:bg-white/20 
                           text-white rounded-lg px-4 py-2 transition-all duration-200"
                >
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M3,20.5V3.5C3,2.91 3.34,2.39 3.84,2.15L13.69,12L3.84,21.85C3.34,21.6 3,21.09 3,20.5M16.81,15.12L6.05,21.34L14.54,12.85L16.81,15.12M20.16,10.81C20.5,11.08 20.75,11.5 20.75,12C20.75,12.5 20.5,12.92 20.16,13.19L17.89,14.5L15.39,12L17.89,9.5L20.16,10.81M6.05,2.66L16.81,8.88L14.54,11.15L6.05,2.66Z"/>
                  </svg>
                  Get it on Google Play
                </a>
                
                <a 
                  href="https://apps.apple.com/in/app/zulu-club/id6739531325"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center gap-2 bg-white/10 hover:bg-white/20 
                           text-white rounded-lg px-4 py-2 transition-all duration-200"
                >
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11"/>
                  </svg>
                  Download on App Store
                </a>
              </div>

              <button
                onClick={() => setShowDownloadPopup(false)}
                className="mt-6 text-sm text-gray-400 hover:text-white transition-colors duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default HeroSection;