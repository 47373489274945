import React, { useEffect, useState, useRef } from "react";
import { ChevronLeft, ChevronRight } from 'lucide-react';

const LatestCollections = () => {
  const [videos, setVideos] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);
  const videoRefs = useRef([]);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const collectionVideos = [
      "https://zulushop.in/uploads/media/2024/1000002075.mp4",
      "https://zulushop.in/uploads/media/2024/1000002074.mp4",
      "https://zulushop.in/uploads/media/2024/10001166791.mp4",
      "https://zulushop.in/uploads/media/2024/10001166831.mp4",
      "https://zulushop.in/uploads/media/2024/10001157252.mp4",
      "https://zulushop.in/uploads/media/2024/10000710892.mp4",
      "https://zulushop.in/uploads/media/2024/10000710862.mp4",
      "https://zulushop.in/uploads/media/2024/10000710874.mp4",
      "https://zulushop.in/uploads/media/2024/1000020575.mp4",
    "https://zulushop.in/uploads/media/2024/1000020574.mp4",
    "https://zulushop.in/uploads/media/2024/1000083652.mp4",
    "https://zulushop.in/uploads/media/2024/1000018884.mp4",
    "https://zulushop.in/uploads/media/2024/1000082760.mp4",
    "https://zulushop.in/uploads/media/2024/1000018425.mp4",
    "https://zulushop.in/uploads/media/2024/1000082650.mp4",
    "https://zulushop.in/uploads/media/2024/10000826411.mp4",
    "https://zulushop.in/uploads/media/2024/1000082372.mp4",
    "https://zulushop.in/uploads/media/2024/1000017441.mp4",
    "https://zulushop.in/uploads/media/2024/1000017427.mp4",
    "https://zulushop.in/uploads/media/2024/1000017417.mp4",
    "https://zulushop.in/uploads/media/2024/1000017401.mp4",
    "https://zulushop.in/uploads/media/2024/1000017393.mp4",
    "https://zulushop.in/uploads/media/2024/1000017363.mp4",
    "https://zulushop.in/uploads/media/2024/1000002075.mp4",
      "https://zulushop.in/uploads/media/2024/1000002074.mp4",
      "https://zulushop.in/uploads/media/2024/10001166791.mp4",
      "https://zulushop.in/uploads/media/2024/10001166831.mp4",
      "https://zulushop.in/uploads/media/2024/10001157252.mp4",
      "https://zulushop.in/uploads/media/2024/10000710892.mp4",
      "https://zulushop.in/uploads/media/2024/10000710862.mp4",
      "https://zulushop.in/uploads/media/2024/10000710874.mp4",
      "https://zulushop.in/uploads/media/2024/1000020575.mp4",
      "https://zulushop.in/uploads/media/2024/1000020574.mp4",
      "https://zulushop.in/uploads/media/2024/1000083652.mp4",
      "https://zulushop.in/uploads/media/2024/1000018884.mp4",
      "https://zulushop.in/uploads/media/2024/1000082760.mp4",
      "https://zulushop.in/uploads/media/2024/1000018425.mp4",
      "https://zulushop.in/uploads/media/2024/1000082650.mp4",
      "https://zulushop.in/uploads/media/2024/10000826411.mp4",
      "https://zulushop.in/uploads/media/2024/1000082372.mp4",
      "https://zulushop.in/uploads/media/2024/1000017441.mp4",
      "https://zulushop.in/uploads/media/2024/1000017427.mp4",
      "https://zulushop.in/uploads/media/2024/1000017417.mp4",
      "https://zulushop.in/uploads/media/2024/1000017401.mp4",
      "https://zulushop.in/uploads/media/2024/1000017393.mp4",
      "https://zulushop.in/uploads/media/2024/1000017353.mp4",
      "https://zulushop.in/uploads/media/2024/1000017319.mp4",
      "https://zulushop.in/uploads/media/2024/1000017299.mp4",
      "https://zulushop.in/uploads/media/2024/1000017291.mp4"
 
    ];

    const shuffledVideos = [...collectionVideos].sort(() => Math.random() - 0.5);
    setVideos(shuffledVideos);
  }, []);

  const handleVideoEnd = () => {
    const nextIndex = (currentVideoIndex + 1) % videos.length;
    setCurrentVideoIndex(nextIndex);
    playVideo(nextIndex);
  };

  const playVideo = (index) => {
    if (videoRefs.current[currentVideoIndex]) {
      videoRefs.current[currentVideoIndex].pause();
    }
    if (videoRefs.current[index]) {
      videoRefs.current[index].play();
    }
  };

  const handleMouseEnter = (videoRef) => {
    if (videoRef && videoRef.paused) {
      videoRef.play();
    }
  };

  const handleMouseLeave = (videoRef) => {
    if (videoRef && !videoRef.paused) {
      videoRef.pause();
    }
  };

  const handlePrevVideo = () => {
    const newIndex = currentVideoIndex > 0 ? currentVideoIndex - 1 : videos.length - 1;
    setCurrentVideoIndex(newIndex);
    playVideo(newIndex);
  };

  const handleNextVideo = () => {
    const newIndex = (currentVideoIndex + 1) % videos.length;
    setCurrentVideoIndex(newIndex);
    playVideo(newIndex);
  };

  return (
    <div className="bg-black py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl md:text-4xl font-bold text-white text-center mb-4">
          Latest Collections
        </h1>
        <p className="text-center text-lg md:text-xl text-gray-300 font-light mb-10">
          Discover the Best of Gurugram's Fashion
        </p>

        {/* Mobile Carousel */}
        <div className="md:hidden relative">
          <div ref={containerRef} className="relative overflow-hidden rounded-lg">
            <div 
              className="flex transition-transform duration-300 ease-in-out"
              style={{ transform: `translateX(-${currentVideoIndex * 100}%)` }}
            >
              {videos.slice(0, 6).map((videoUrl, index) => (
                <div key={index} className="w-full flex-shrink-0">
                  <div className="relative aspect-[9/16] bg-white/5 border border-white/10 rounded-lg overflow-hidden">
                    <video
                      ref={el => videoRefs.current[index] = el}
                      className="w-full h-full object-cover"
                      src={videoUrl}
                      loop={false}
                      muted
                      playsInline
                      autoPlay={index === currentVideoIndex}
                      onEnded={handleVideoEnd}
                    />
                  </div>
                </div>
              ))}
            </div>

            {/* Navigation Buttons */}
            <button
              onClick={handlePrevVideo}
              className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 backdrop-blur-sm 
                       text-white hover:bg-black/70 transition-all duration-200"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            <button
              onClick={handleNextVideo}
              className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 backdrop-blur-sm 
                       text-white hover:bg-black/70 transition-all duration-200"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>

          {/* Carousel Indicators */}
          <div className="flex justify-center gap-2 mt-4">
            {videos.slice(0, 6).map((_, index) => (
              <button
                key={index}
                className={`w-2 h-2 rounded-full transition-all duration-200 
                  ${currentVideoIndex === index ? 'bg-white w-4' : 'bg-white/30'}`}
                onClick={() => {
                  setCurrentVideoIndex(index);
                  playVideo(index);
                }}
              />
            ))}
          </div>
        </div>

        {/* Desktop Grid */}
        <div className="hidden md:grid grid-cols-2 lg:grid-cols-5 gap-6">
          {videos.slice(0, 5).map((videoUrl, index) => (
            <div
              key={index}
              className="relative aspect-[9/16] bg-white/5 border border-white/10 rounded-lg overflow-hidden 
                       transform transition duration-300 hover:scale-105"
            >
              <video
                ref={el => videoRefs.current[index] = el}
                className="w-full h-full object-cover"
                src={videoUrl}
                loop
                muted
                playsInline
                onMouseEnter={(e) => handleMouseEnter(e.target)}
                onMouseLeave={(e) => handleMouseLeave(e.target)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestCollections;