import React, { useRef } from 'react';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import GetStartedSection from '../components/GetStartedSection';
import MarketsGrid from '../components/MarketsGrid';
import BrandsGrid from '../components/BrandsGrid';
import Footer from '../components/Footer';
import ExperienceZulu from '../components/ExperienceZulu';
import FAQ from '../components/FAQ';
import LatestCollections from '../components/LatestCollections';
import FeaturedMalls from '../components/FeaturedMalls';
import TeamSection from '../components/TeamSection';
import ChatInterface from '../components/ChatInterface';

const ConsumerPage = () => {
  const heroRef = useRef(null);
  const marketsRef = useRef(null);
  const brandsRef = useRef(null);
  const latestCollectionsRef = useRef(null);
  const experienceRef = useRef(null);

  const scrollToHero = () => heroRef.current?.scrollIntoView({ behavior: 'smooth' });
  const scrollToMarkets = () => marketsRef.current?.scrollIntoView({ behavior: 'smooth' });
  const scrollToBrands = () => brandsRef.current?.scrollIntoView({ behavior: 'smooth' });
  const scrollToExperience = () => experienceRef.current?.scrollIntoView({ behavior: 'smooth' });

  return (
    <div className='bg-black'>
      <div className="bg-black">
        <div className='h-[100vh]'>
          <div className="relative bg-[url('./assets/bg-opt.png')] bg-black bg-cover bg-no-repeat bg-center w-full h-[100%] mx-auto">
            <div className="absolute inset-0 bg-black/60 z-10"></div>
            <div className="relative z-20 flex flex-col items-center justify-center h-full">
              <Navbar />
              <HeroSection ref={heroRef} />
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <GetStartedSection
            onCardClick={[scrollToMarkets, scrollToBrands, scrollToExperience]}
          />
        </div>
        <div className='mt-10'>
          <FeaturedMalls />
        </div>
        {/* <div ref={marketsRef} className='mt-10'>
          <MarketsGrid scrollToHero={scrollToHero} scrollToBrands={scrollToBrands} />
        </div> */}

        <div ref={brandsRef} className='md:mt-6'>
          <BrandsGrid />
        </div>

        

        <div ref={latestCollectionsRef} className='md:mt-10'>
          <LatestCollections />
        </div>

        <div ref={experienceRef} className='mt-10'>
          <ExperienceZulu />
        </div>

        <div className='md:mt-[-150px]'>
          <FAQ />
        </div>

        <div className='mt-10'>
          <TeamSection />
        </div>

        <ChatInterface />

        <Footer />
      </div>
    </div>
  );
};

export default ConsumerPage;
