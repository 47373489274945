import React, { useState, useRef, useEffect } from 'react';
import { MessageCircle, X, Minimize2, Maximize2 } from 'lucide-react';
import ConversationalAgent from '../agents/ConversationalAgent';

const ChatInterface = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const agent = useRef(new ConversationalAgent());

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim() || isLoading) return;

    const userMessage = {
      text: inputValue.trim(),
      sender: 'user'
    };

    try {
      setIsLoading(true);
      setMessages(prev => [...prev, userMessage]);
      setInputValue('');

      console.log('Sending message:', userMessage.text); // Debug log

      const response = await agent.current.handleQuery(
        userMessage.text,
        messages.map(msg => ({
          text: msg.text,
          sender: msg.sender,
          context: msg.context
        }))
      );

      console.log('Received response:', response); // Debug log

      if (response && response.reply) {
        const aiMessage = {
          text: response.reply,
          sender: 'ai',
          context: response.context
        };
        setMessages(prev => [...prev, aiMessage]);
      } else {
        throw new Error('Invalid response format');
      }

    } catch (error) {
      console.error('Chat error:', error);
      setMessages(prev => [...prev, {
        text: "I'm sorry, I encountered an error. Please try again.",
        sender: 'ai'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  // Add initial greeting when chat is opened
  const handleChatOpen = () => {
    setIsOpen(true);
    if (messages.length === 0) {
      setMessages([{
        text: "Hi! I'm your personal shopping assistant. How can I help you today?",
        sender: 'ai'
      }]);
    }
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {/* Chat Toggle Button */}
      {!isOpen && (
        <button
          onClick={handleChatOpen}
          className="bg-white text-black p-4 rounded-full shadow-lg hover:bg-gray-100 transition-all duration-200"
        >
          <MessageCircle size={24} />
        </button>
      )}

      {/* Chat Window */}
      {isOpen && (
        <div className={`bg-black border border-white/10 rounded-lg shadow-lg overflow-hidden
                        ${isMinimized ? 'w-72 h-14' : 'w-96 h-[600px]'}`}>
          {/* Chat Header */}
          <div className="bg-white/5 p-4 flex justify-between items-center">
            <h3 className="text-white font-medium">Shopping Assistant</h3>
            <div className="flex gap-2">
              <button
                onClick={() => setIsMinimized(!isMinimized)}
                className="text-white/70 hover:text-white"
              >
                {isMinimized ? <Maximize2 size={18} /> : <Minimize2 size={18} />}
              </button>
              <button
                onClick={() => setIsOpen(false)}
                className="text-white/70 hover:text-white"
              >
                <X size={18} />
              </button>
            </div>
          </div>

          {!isMinimized && (
            <>
              {/* Messages Container */}
              <div className="h-[480px] overflow-y-auto p-4 space-y-4">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                  >
                    <div
                      className={`max-w-[80%] rounded-lg px-4 py-2 ${
                        message.sender === 'user'
                          ? 'bg-white/10 text-white'
                          : 'bg-white/5 text-gray-200'
                      }`}
                    >
                      {message.text}
                    </div>
                  </div>
                ))}
                {isLoading && (
                  <div className="flex justify-start">
                    <div className="bg-white/5 text-gray-200 rounded-lg px-4 py-2">
                      Thinking...
                    </div>
                  </div>
                )}
                <div ref={messagesEndRef} />
              </div>

              {/* Input Form */}
              <form onSubmit={handleSubmit} className="p-4 bg-white/5">
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Ask about products..."
                    className="flex-1 bg-white/5 border border-white/10 rounded-lg px-4 py-2 
                             text-white placeholder:text-gray-500 focus:outline-none focus:ring-2 
                             focus:ring-white/20"
                    disabled={isLoading}
                  />
                  <button
                    type="submit"
                    disabled={isLoading || !inputValue.trim()}
                    className="bg-white/10 hover:bg-white/20 text-white px-4 py-2 rounded-lg 
                             transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Send
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatInterface; 